<template>
  <v-container class="mx-6 px-1" id="pageContainer">
    <v-row justify-space-around>
      <v-col >
        <div class="text-h4">
          {{ $t('status_report.title') }} {{ formattedDate }}
        </div>
      </v-col>

        <v-col cols="1" class="pt-5 mr-5 no-print">
            <v-btn
                download
                @click="getCSV()">
                CSV
            </v-btn>
        </v-col>

      <v-col cols="1" class="pa-0 pt-3 no-print" style="min-width:120px;">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="width:120px"
              v-model="formattedDate"
              :label="$t('status_report.deadline')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="statusDate" no-title scrollable>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="statusSection">
      <v-container class="mx-0 rowContainer">
        <v-row>
          <div class="text-h5 sectionTitle">
            {{ $t('status_report.camera_definition') }}
          </div>
        </v-row>

        <v-row class="rowTable">
          <v-data-table
            :headers="definitionHeaders"
            :items="definitions"
            :items-per-page="50"
            hide-default-footer
            class="elevation-0"
            id="definitionsTable"
          ></v-data-table>
        </v-row>

        <v-row>
          <v-simple-table class="smallStatusTable">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">{{ $t('status_report.in_use') }}</th>
                  <th class="text-left">{{ $t('status_report.active_value') }}</th>
                </tr>
              </thead>
              <tbody id="definitionsTotalTable">
                <tr v-for="item in definitionsTotal" :key="item.name">
                  <td>Total</td>
                  <td>{{ item.inUse }}</td>
                  <td>{{ item.valueStr }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="statusSection">
      <v-container class="mx-0 rowContainer">
        <v-row>
          <div class="text-h5 sectionTitle">
            {{ $t('status_report.possible_systems') }}
          </div>
        </v-row>

        <v-row class="rowTable">
          <v-data-table
            :headers="possibleHeaders"
            :items="possibles"
            :items-per-page="50"
            hide-default-footer
            class="elevation-0"
          ></v-data-table>
        </v-row>

        <v-row>
          <v-data-table
            :headers="stockHeaders"
            :items="stocks"
            :items-per-page="50"
            hide-default-footer
            class="elevation-0"
          ></v-data-table>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="statusSection">
      <v-container class="mx-0 rowContainer">
        <v-row>
          <div class="text-h5 sectionTitle">
            {{ actives.length }} {{ $t('status_report.active_systems') }}
          </div>
        </v-row>

        <v-row>
          <v-data-table
            :headers="activeHeaders"
            :items="actives"
            :items-per-page="500"
            :sort-by.sync="activeSortBy"
            :sort-desc.sync="activeSortDesc"
            hide-default-footer
            class="elevation-0"
          ></v-data-table>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'

const moment = require('moment-timezone')
const FileDownload = require('js-file-download')

export default {
    data() {
        return {
            statusDate: moment().format('YYYY-MM-DD'),
            dateMenu: false,

            definitionHeaders: [
                {
                    text: this.$t('System Name'),
                    align: 'start',
                    value: 'name',
                },
                { text: this.$t('In Use'), value: 'inUse', align: 'end' },
                { text: this.$t('Content'), value: 'description', sortable: false },
                { text: this.$t('Costs'), value: 'priceStr', align: 'end' },
                { text: this.$t('Active Value'), value: 'valueStr', align: 'end' },
            ],
            definitions: [],
            definitionsTotal: [],

            possibleHeaders: [],
            possibles: [],

            stockHeaders: [],
            stocks: [],

            activeHeaders: [
                {
                    text: this.$t('Client'),
                    align: 'start',
                    value: 'client',
                },
                { text: this.$t('Project'), value: 'project' },
                { text: this.$t('Camera ID'), value: 'systemId' },
                { text: this.$t('Start Date'), value: 'startDate' },
                { text: this.$t('End Date'), value: 'endDate' },
                { text: this.$t('Version'), value: 'version' },
            ],
            actives: [],
            activeSortBy: 'project',
            activeSortDesc: false,

            products: [],
            locations: [],
            date: moment(),
            productVersionIdMap: {},
        }
    },

    mounted() {
        if (this.$route.query.date) {
            this.date = moment(this.$route.query.date)
            this.statusDate = this.date.format('YYYY-MM-DD')
        }
    },

    created() {
        this.showSpinner(false)
    },

    components: {

    },

    computed: {
        formattedDate() {
            this.getData()
            return moment(this.statusDate).format('DD.MM.YYYY')
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
        }),

        getNumberRepr(number) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(number)
        },

        getData() {
            this.getLocations()
                .then(() => this.getDefinitions())
                .then(() => rest.getActiveProducts(moment(this.statusDate)))
                .then(products => {
                    let totalPrice = 0
                    let totalPriceStr = ''

                    this.actives = []
                    this.definitionsTotal = []
                    let activeProducts = 0

                    for (const product of products) {
                        if (product.version === 'Satellite') continue
                        if (product.version === '') product.version = 'Unknown'

                        const productIdx = this.productVersionIdMap[product.version]
                        if (!Object.hasOwnProperty.call(this.definitions[productIdx], 'inUse')) {
                            this.definitions[productIdx].inUse = 0
                            this.definitions[productIdx].value = 0
                        }

                        const newDefinition = this.definitions[productIdx]
                        newDefinition.inUse += 1

                        if (newDefinition.price) {
                            newDefinition.value += newDefinition.price
                            totalPrice += newDefinition.price
                            newDefinition.valueStr = this.getNumberRepr(newDefinition.value)
                            totalPriceStr = this.getNumberRepr(totalPrice)
                        }

                        this.$set(this.definitions, productIdx, newDefinition)

                        this.actives.push(product)
                        activeProducts += 1
                    }

                    this.definitionsTotal.push({
                        inUse: activeProducts,
                        valueStr: totalPriceStr,
                    })

                    return rest.getPossibleProducts(moment(this.statusDate))
                })
                .then(locationStock => {
                    let firstLoop = true
                    this.possibles = []
                    this.stocks = []
                    this.stockHeaders = [{
                        text: this.$t('Stock Location'),
                        align: 'start',
                        value: 'location',
                    }]

                    for (const stock of locationStock) {
                        const locationStr = stock.location.charAt(0).toUpperCase() + stock.location.slice(1)
                        const stockData = { location: locationStr }

                        const possibleProducts = this.getStockProducts(stock)
                        possibleProducts.location = locationStr
                        this.possibles.push(possibleProducts)

                        for (const item in stock) {
                            if (item === 'location') continue

                            if (firstLoop) {
                                this.stockHeaders.push({
                                    text: item.charAt(0).toUpperCase() + item.slice(1),
                                    align: 'end',
                                    value: item.replace(/\s+/, ''),
                                })
                            }

                            stockData[item.replace(/\s+/, '')] = stock[item]
                        }
                        firstLoop = false

                        this.stocks.push(stockData)
                    }
                })
        },

        getCSV() {
            return rest.getActiveProducts(moment(this.statusDate), true)
                .then(response => {
                    FileDownload(response.data, `Status_Report_${moment(this.statusDate).format('YYYY-MM-DD')}.csv`)
                })
        },

        getDefinitions() {
            return rest.getRows('product')
                .then(products => {
                    this.definitions = products
                    this.possibleHeaders = [{
                        text: this.$t('Stock Location'),
                        align: 'start',
                        value: 'location',
                    }]

                    this.definitions.push({ name: 'Unknown' })

                    for (const idx in this.definitions) {
                        if (idx) {
                            const product = this.definitions[idx]
                            if (!Object.hasOwnProperty.call(this.productVersionIdMap, product.name)) {
                                this.productVersionIdMap[product.name] = idx
                            }

                            product.priceStr = product.price ? this.getNumberRepr(product.price) : ''

                            this.possibleHeaders.push({
                                text: product.name,
                                align: 'end',
                                value: product.name.toLowerCase().replace(/\s+/g, ''),
                            })
                        }
                    }

                    this.possibleHeaders.push({
                        text: 'AnschWert',
                        align: 'end',
                        value: 'valueStr',
                    })

                    return true
                })
        },

        getLocations() {
            return rest.getRows('location')
                .then(locations => {
                    this.locations = locations
                    return true
                })
        },

        getStockProducts(stockDataSrc) {
            const stockData = { ...stockDataSrc }
            const stockProducts = {}
            let stockValue = 0

            // MK 4 II
            const compareData = [
                { name: 'MK 4 II', values: ['dwenger', 'm1'] },
                { name: 'MK 4 I', values: ['dwenger', 'm5'] },
                { name: 'MK 3', values: ['dwenger', 'g5'] },
                { name: 'MK 2 II', values: ['zarges', 'm5'] },
                { name: 'MK 2 I', values: ['zarges', 'g5'] },
                { name: 'MK 1', values: ['zarges', 'canon'] },
                { name: 'Rose II', values: ['rose', 'm5'] },
                { name: 'Rose I', values: ['rose', 'g5'] },
                { name: 'Seitz', values: ['seitz'] },
                { name: 'Axis', values: ['axis'] },
                { name: 'Ulysse', values: ['ulysse'] },
                { name: 'MK Lite', values: ['enlaps'] },
                { name: 'MK Lite V2', values: ['hikvision'] },
                { name: 'Mobotix', values: ['mobotix'] },
            ]

            for (const cData of compareData) {
                const cValues = cData.values
                let minSys = Math.min(...cValues.map(x => stockData[x]))

                if (minSys > 0) {
                    stockData[cValues[0]] -= minSys
                    if (cValues.length > 1) stockData[cValues[1]] -= minSys
                } else {
                    minSys = 0
                }
                stockProducts[cData.name.toLowerCase().replace(/\s+/g, '')] = minSys
                const productIdx = this.productVersionIdMap[cData.name]
                stockValue += minSys * this.definitions[productIdx].price
            }
            stockProducts.value = stockValue
            stockProducts.valueStr = this.getNumberRepr(stockValue)

            return stockProducts
        },

    },
}
</script>

<style>
    @media print {
        @page {
            size: landscape; /*automatically set to Landscape only*/
        }
    }
</style>

<style lang="stylus">
  .statusSection
    margin-bottom 3em

  .sectionTitle
    margin-bottom 1em

  .rowTable
    margin-bottom 2em

  #definitionsTotalTable tr:last-child
    font-weight bold

  #pageContainer
    max-width: calc(100% - 80px)

  .rowContainer
    max-width 100%

  .v-data-table
    width 100%

  .smallStatusTable
    width unset

  @media print
    header.v-app-bar
      display none

    .no-print
        display none
</style>
